<template>
  <div v-click-outside="hideSearch" class="header-search">
    <button class="header-search-button" aria-label="toggle search input" @click.prevent="toggleSearch">
      <IconSearch />
    </button>
    <Transition name="header-search-fade">
      <form v-show="isSearchShown" id="header-search" class="header-search-overlay" @submit.prevent="submitSearch">
        <DefaultInput
          ref="refInput"
          v-model="searchTerm"
          :value="searchTerm"
          class="header-search-input"
          type="search"
          placeholder="Wonach suchen Sie?"
        />
        <button type="submit" form="header-search" value="submit search" class="header-search-trigger">
          <span class="visually-hidden">Search Term</span>
          <IconSearch class="_icon" />
        </button>
      </form>
    </Transition>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import IconSearch from '@/components/ui/icons/icon-search.svg'
import DefaultInput from '@/components/ui/form/input/default'

export default {
  components: {
    IconSearch,
    DefaultInput,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      searchTerm: null,
      isSearchShown: false,
    }
  },
  created() {
    this.$nuxt.$on('openedMainNav', this.hideSearch)
  },
  methods: {
    toggleSearch() {
      this.isSearchShown = !this.isSearchShown
      if (this.isSearchShown) {
        this.$nuxt.$emit('openedSearch')
        this.$nextTick(() => {
          this.$refs.refInput.$el.focus()
        })
      }
    },
    hideSearch() {
      this.isSearchShown = false
    },
    submitSearch() {
      // eslint-disable-next-line no-console
      console.log(this.searchTerm)
      this.$router.push({
        path: '/suche',
        query: { q: this.searchTerm },
      })
    },
  },
}
</script>

<style lang="scss">
@use '../../../css/import/vars.scss';
@use '../../../css/import/mixins.scss';

.header-search {
  position: relative;
  display: none;

  @media all and (min-width: vars.$screen-width-767) {
    display: block;
  }
}

.header-search-button {
  background-color: vars.$transparent;
}

.header-search-overlay {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 5;
  padding: 20px;
  background-color: vars.$color-white;
  transition-duration: 300ms;
  box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.4);

  .header-search-input {
    width: 250px;
    padding-right: 40px;
    font-family: vars.$source-sans-pro-variable;
    font-size: 16px;
    line-height: 1;

    &:active,
    &:focus {
      border: 1px solid vars.$color-turquoise;
      transition-duration: 0.3s;
    }
  }

  @media screen and (min-width: vars.$screen-width-767) {
    .header-search-input {
      width: 400px;
    }
  }
}

.header-search-trigger {
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  width: 42px;
  height: 40px;
  background-color: vars.$transparent;

  ._icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    path {
      fill: vars.$color-text-gray !important;
    }
  }
}

.header-search-fade-enter-active,
.header-search-fade-leave-active {
  transition-property: transform, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.header-search-fade-enter,
.header-search-fade-leave-to {
  opacity: 0;
  transform: translate3d(-6px, 0, 0);
}

.header-search-fade-leave,
.header-search-fade-enter-to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
</style>
