<template>
  <ConsentRequest :button-text="buttonText" @consented="onConsented">
    <div class="consent-request-piano">
      <h3>
        {{ title }}
        Ihre derzeitigen Cookie Einstellungen erlauben es nicht, diesen Inhalt zu laden. Wir brauchen dafür Ihre
        freiwillige Einwilligung.
      </h3>
      <p>
        Wir verwenden für die Benutzerverwaltung wie auch digitale Abobestellungen und digitale Verkaufsaktivitäten (z.
        B. Anzeige auch personalisierter Aboangebote und hierzu Erstellen eines Nutzerprofils) Services unseres
        Dienstleisters Piano Software Inc. („Piano"). Dabei kommen Technologien wie Cookies zum Einsatz. Mit Klick auf
        „Einwilligung erteilen und anmelden" stimmen Sie der Verwendung von Piano zu diesen Zwecken zu und über Ihren
        Browser werden Informationen (darunter auch personenbezogene Daten) verarbeitet. Sie können Ihre erteilte
        Einwilligung jederzeit mit Wirkung für die Zukunft über den Button „Cookie Einstellungen" am Ende unserer
        Webseite widerrufen. Die Datenschutzinformation der FURCHE können Sie
        <a href="https://www.furche.at/datenschutz" target="_blank">hier</a> einsehen.
      </p>
    </div>
  </ConsentRequest>
</template>

<script>
import ConsentRequest from '@/components/consent/ConsentRequest'

export default {
  components: {
    ConsentRequest,
  },
  props: {
    context: {
      type: String,
      default: 'offers',
      validator: (value) => ['offers', 'login'].includes(value),
    },
  },
  computed: {
    title() {
      if (this.context === 'login') {
        return 'Hier können Sie sich für Ihr FURCHE-Benutzerkonto anmelden oder neu registrieren.'
      }

      if (this.context === 'offers') {
        return 'Hier möchten wir Ihnen FURCHE-Aboangebote anzeigen.'
      }

      return 'Hier möchten wir Ihnen FURCHE-Aboangebote anzeigen.'
    },
    buttonText() {
      if (this.context === 'login') {
        return 'Einwilligung erteilen und anmelden'
      }

      return 'Einwilligung erteilen und Inhalt anzeigen'
    },
    isPianoReady() {
      return this.$store.getters['tinypass/isPianoReady']
    },
  },
  methods: {
    onConsented() {
      const unsubscribe = this.$watch(
        () => this.isPianoReady,
        (isReady) => {
          if (isReady) {
            this.$emit('ready')
            unsubscribe()
          }
        }
      )

      this.$store.dispatch('consent/giveConsentToPiano')
    },
  },
}
</script>

<style lang="scss">
@use '../../css/import/vars';

.consent-request-piano {
  max-width: vars.$content-text-max-width;
  margin: auto;
  text-align: center;
}
</style>
