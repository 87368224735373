<template>
  <header :class="[darkmode ? 'darkmode' : '', 'page-header noprint']" role="banner">
    <NuxtLink :to="{ name: 'index' }">
      <FurcheLogo />
    </NuxtLink>
    <HeaderNavigation />
  </header>
</template>

<script>
import FurcheLogo from '@/components/ui/icons/FurcheLogo1945.svg'
import HeaderNavigation from '@/components/header/components/navigation'

export default {
  components: {
    FurcheLogo,
    HeaderNavigation,
  },
  props: {
    darkmode: {
      type: Boolean,
      required: false,
    },
  },
}
</script>

<style lang="scss">
@use '../../css/import/vars.scss';

.page-header {
  position: absolute;
  top: 0;
  padding: 10px 0;
  width: 100%;
  height: 59px;
  min-width: 320px;
  margin-bottom: 30px;
  border-bottom: 1px solid vars.$color-light-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  transition-duration: 0.3s;
  z-index: 30;

  @media screen and (min-width: vars.$screen-width-767) {
  }

  @media screen and (min-width: vars.$screen-width-1023) {
    height: 120px;
    max-width: vars.$content-max-width;
    padding-left: 20px;
    padding-right: 6px;
  }

  @media screen and (min-width: vars.$screen-width-1300) {
    padding-left: 0;
  }

  &.darkmode {
    svg,
    path,
    text {
      fill: vars.$color-white;
    }

    @media print {
      svg,
      path,
      text {
        fill: vars.$color-red;
      }
      text {
        fill: #373739;
        display: block;
      }
    }
  }

  .logoSVG {
    transform: scale(0.72) translate(-20px, 14px);

    @media screen and (min-width: vars.$screen-width-1023) {
      transform: scale(1) translate(0, 0);
    }
  }
  .logoText {
    display: none;

    @media screen and (min-width: vars.$screen-width-1023) {
      display: block;
    }
  }
}
</style>
