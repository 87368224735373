<template>
  <input :value="value" type="text" class="inputfield--default" @input="$emit('input', $event.target.value)" />
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss">
@use '../../../../css/import/vars';

.inputfield--default {
  width: 100%;
  border: 1px solid vars.$color-dark-grey;
  border-radius: 3px;
  padding: 14px;
}
</style>
